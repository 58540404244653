// const GOOGLE_FORM_URL = "https://forms.gle/gqJBzJpreVezHTqMA";
const TYPEFORM_URL = "https://qpc056akpxf.typeform.com/to/cVhgLFX5";

const Pricing = () => {
  return (
    <section
      id="pricing"
      className="relative z-20 overflow-hidden bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
    >
      <div className="container">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[620px] text-center lg:mb-20">
              <span className="mb-2 block text-lg font-semibold text-primary">
                Precios
              </span>
              <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px]">
                Nuestros planes
              </h2>
              <p className="text-lg leading-relaxed text-body-color sm:text-xl sm:leading-relaxed">
                Aquí tienes alguno de los planes más solicitados
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap items-center justify-center">
          <div className="w-full md:w-1/2 lg:w-1/4">
            <div
              className="wow fadeInUp relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 text-center shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
              data-wow-delay=".15s
                "
            >
              <span className="mb-2 block text-base font-medium uppercase text-dark">
                EMPRENDEDOR
              </span>

              {/* ~12 USD */}
              <h2 className="mb-9 text-[28px] font-semibold text-primary">
                $28000
              </h2>

              <div className="mb-10">
                <p className="mb-1 text-base font-medium leading-loose text-body-color">
                  1 usuario
                </p>
                <p className="mb-1 text-base font-medium leading-loose text-body-color">
                  Hasta $1.750.000 de facturación
                </p>
                <p className="mb-1 text-base font-medium leading-loose text-body-color">
                  Lista de Precios Fija
                </p>
                <p className="mb-1 text-base font-medium leading-loose text-body-color">
                  Soporte básico
                </p>
              </div>
              <div className="w-full">
                <a
                  className="inline-block rounded-full border border-[#D4DEFF] bg-transparent py-4 px-11 text-center text-base font-medium text-primary transition duration-300 ease-in-out hover:border-primary hover:bg-primary hover:text-white"
                  target="_blank"
                  rel="noreferrer"
                  href={TYPEFORM_URL}
                >
                  Lo quiero
                </a>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4">
            <div
              className="wow fadeInUp relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 text-center shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
              data-wow-delay=".15s"
            >
              <span className="mb-2 block text-base font-medium uppercase text-dark">
                PYMES
              </span>
              {/* ~25 USD */}
              <h2 className="mb-9 text-[28px] font-semibold text-primary">
                $48000
              </h2>

              <div className="mb-10">
                <p className="mb-1 text-base font-medium leading-loose text-body-color">
                  2 usuarios
                </p>
                <p className="mb-1 text-base font-medium leading-loose text-body-color">
                  Hasta $6.000.000 de facturación
                </p>
                <p className="mb-1 text-base font-medium leading-loose text-body-color">
                  Lista de Precios Fija
                </p>
                <p className="mb-1 text-base font-medium leading-loose text-body-color">
                  Soporte básico
                </p>
              </div>
              <div className="w-full">
                <a
                  className="inline-block rounded-full border border-[#D4DEFF] bg-transparent py-4 px-11 text-center text-base font-medium text-primary transition duration-300 ease-in-out hover:border-primary hover:bg-primary hover:text-white"
                  target="_blank"
                  rel="noreferrer"
                  href={TYPEFORM_URL}
                >
                  Lo quiero
                </a>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4">
            <div
              className="wow fadeInUp relative z-10 mb-10 overflow-hidden rounded-xl bg-primary bg-gradient-to-b from-primary to-[#179BEE] py-10 px-8 text-center shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
              data-wow-delay=".1s"
            >
              <span className="mb-5 inline-block rounded-full border border-white bg-white py-2 px-6 text-base font-semibold uppercase text-primary">
                PRO
              </span>

              {/* ~38 USD */}
              <h2 className="mb-9 text-[28px] font-semibold text-white">
                $69000
              </h2>

              <div className="mb-10">
                <p className="mb-1 text-base font-medium leading-loose text-white">
                  5 usuarios
                </p>
                <p className="mb-1 text-base font-medium leading-loose text-white">
                  Hasta $12.000.000 de facturación
                </p>
                <p className="mb-1 text-base font-medium leading-loose text-white">
                  Lista de Precios Dinámica
                </p>
                <p className="mb-1 text-base font-medium leading-loose text-white">
                  Permisos predeterminados
                </p>
                <p className="mb-1 text-base font-medium leading-loose text-white">
                  Soporte intermedio
                </p>
              </div>
              <div className="w-full">
                <a
                  className="inline-block rounded-full border border-white bg-white py-4 px-11 text-center text-base font-medium text-dark transition duration-300 ease-in-out hover:border-dark hover:bg-dark hover:text-white"
                  target="_blank"
                  rel="noreferrer"
                  href={TYPEFORM_URL}
                >
                  Lo quiero
                </a>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4">
            <div
              className="wow fadeInUp relative z-10 mb-10 overflow-hidden rounded-xl border border-primary border-opacity-20 bg-white py-10 px-8 text-center shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-12"
              data-wow-delay=".15s"
            >
              <span className="mb-2 block text-base font-medium uppercase text-dark">
                PLUS
              </span>

              {/* ~50 USD */}
              <h2 className="mb-9 text-[28px] font-semibold text-primary">
                $85000
              </h2>

              <div className="mb-10">
                <p className="mb-1 text-base font-medium leading-loose text-body-color">
                  10 Usuarios
                </p>
                <p className="mb-1 text-base font-medium leading-loose text-body-color">
                  Hasta $25.000.000 de facturación
                </p>
                <p className="mb-1 text-base font-medium leading-loose text-body-color">
                  Permisos personalizados
                </p>
                <p className="mb-1 text-base font-medium leading-loose text-body-color">
                  Soporte personalizado
                </p>
              </div>
              <div className="w-full">
                <a
                  className="inline-block rounded-full border border-[#D4DEFF] bg-transparent py-4 px-11 text-center text-base font-medium text-primary transition duration-300 ease-in-out hover:border-primary hover:bg-primary hover:text-white"
                  target="_blank"
                  rel="noreferrer"
                  href={TYPEFORM_URL}
                >
                  Lo quiero
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
