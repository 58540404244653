import { useEffect, useState } from "react";
import Scripts from "./Scripts";

import Header from "./sections/Header";
import Home from "./sections/Home";
import Contact from "./sections/Contact";
import Features from "./sections/Features";
import About from "./sections/About";
import Pricing from "./sections/Pricing";
import Footer from "./sections/Footer";
import BackToTop from "./sections/BackToTop";
import Integrations from "./sections/Integrations";
import Faq from "./sections/Faq";
import Testimonials from "./sections/Testimonials";
import Privacy from "./sections/Privacy";
import Terms from "./sections/Terms";

const Page = () => {
  const [rendered, setRendered] = useState<boolean>(false);

  useEffect(() => {
    setRendered(true);
  }, []);

  return (
    <>
      <Header />

      <Home />

      <Features />

      <About />

      <Pricing />

      <Integrations />

      <Faq />

      <Testimonials />

      <Contact />

      <Footer />

      <BackToTop />

      {rendered && <Scripts />}
    </>
  );
};

function App() {
  const page = window.location.pathname;

  if (page === "/privacy") {
    return <Privacy />;
  }

  if (page === "/terms") {
    return <Terms />;
  }

  return <Page />;
}

export default App;
