const Privacy = () => {
  return (
    <>
      <h1>Política de Privacidad de Daruma POS</h1>

      <p>
        <strong>Última actualización:</strong> 28 de Septiembre de 2023
      </p>

      <h2>1. Introducción</h2>

      <p>
        Bienvenido a Daruma POS. <br /> En Daruma POS, valoramos la privacidad
        de nuestros usuarios y nos comprometemos a proteger sus datos
        personales. Esta Política de Privacidad describe cómo recopilamos,
        utilizamos y protegemos la información que proporcionas cuando utilizas
        nuestra aplicación y los servicios relacionados.
      </p>

      <h2>2. Información que Recopilamos</h2>

      <p>
        Recopilamos diferentes tipos de información, que incluyen, pero no se
        limitan a:
      </p>

      <h3>2.1 Información de Registro:</h3>
      <p>
        Cuando te registras en Daruma POS, podemos solicitar información como tu
        nombre, dirección de correo electrónico, número de teléfono y datos de
        la empresa.
      </p>

      <h3>2.2 Información de Transacciones:</h3>
      <p>
        Cuando utilizas nuestra aplicación para realizar transacciones,
        recopilamos información sobre las ventas, compras y pagos, que pueden
        incluir información de tarjetas de crédito o débito.
      </p>

      <h3>2.3 Información de Dispositivo:</h3>
      <p>
        Recopilamos información sobre el dispositivo que utilizas para acceder a
        Daruma POS, incluida la dirección IP, el sistema operativo, el tipo de
        dispositivo y la información de navegación.
      </p>

      <h2>3. Uso de la Información</h2>

      <p>
        Utilizamos la información que recopilamos para los siguientes fines:
      </p>

      <h3>3.1 Prestación de Servicios:</h3>
      <p>
        Utilizamos tu información para proporcionar nuestros servicios de punto
        de venta, procesar transacciones y ofrecer soporte al cliente.
      </p>

      <h3>3.2 Comunicación:</h3>
      <p>
        Podemos utilizar tu dirección de correo electrónico o número de teléfono
        para enviarte información relevante sobre Daruma POS, como
        actualizaciones, noticias y promociones.
      </p>

      <h3>3.3 Mejora de la Experiencia del Usuario:</h3>
      <p>
        Analizamos los datos para mejorar nuestros servicios, personalizar tu
        experiencia y garantizar el funcionamiento óptimo de la aplicación.
      </p>

      <h2>4. Compartir Información</h2>

      <p>
        No compartiremos tu información personal con terceros, excepto en las
        siguientes circunstancias:
      </p>

      <h3>4.1 Proveedores de Servicios:</h3>
      <p>
        Podemos compartir información con proveedores de servicios que
        necesitamos para ofrecer nuestros servicios, como procesadores de pagos
        y servicios de alojamiento.
      </p>

      <h3>4.2 Cumplimiento Legal:</h3>
      <p>
        Revelaremos información cuando estemos legalmente obligados a hacerlo
        para cumplir con las leyes y regulaciones aplicables.
      </p>

      <h2>5. Seguridad de Datos</h2>

      <p>
        Tomamos medidas razonables para proteger tu información personal contra
        el acceso no autorizado, la divulgación, la alteración o la destrucción.
      </p>

      <h2>6. Tus Derechos</h2>

      <p>
        Tienes derecho a acceder, corregir o eliminar tus datos personales. Para
        ejercer estos derechos, contáctanos a través de{" "}
        <a href="mailto:info@daruma.com">info@daruma.cloud</a>.
      </p>

      <h2>7. Cambios en la Política de Privacidad</h2>

      <p>
        Nos reservamos el derecho de actualizar esta Política de Privacidad en
        cualquier momento. Te notificaremos sobre los cambios importantes a
        través de la aplicación o por correo electrónico.
      </p>

      <h2>8. Contacto</h2>

      <p>
        Si tienes preguntas o preocupaciones sobre esta Política de Privacidad o
        sobre la forma en que manejamos tus datos personales, comunícate con
        nosotros a través de{" "}
        <a href="mailto:info@daruma.com">info@daruma.cloud</a>.
      </p>

      <p>
        Al utilizar Daruma POS, aceptas los términos y condiciones de esta
        Política de Privacidad. Te recomendamos que la leas detenidamente y la
        revises periódicamente para estar al tanto de las actualizaciones.
      </p>

      <p>
        <strong>Fecha de Última Actualización:</strong> 28 de Septiembre de 2023
      </p>
    </>
  );
};

export default Privacy;
