import axios from "axios";

const axiosClient = axios.create({
  baseURL: "https://graphql.daruma.cloud",
});

export const sendForm = async (data: any) => {
  try {
    await axiosClient.post("/web-form", data);

    return true;
  } catch (e) {
    return false;
  }
};
