import Scripts from "../Scripts";

const Terms = () => {
  return (
    <>
      <Scripts />
    </>
  );
};

export default Terms;
