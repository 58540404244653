import AfipLogo from "../images/AFIP.png";
import TiendaNubeLogo from "../images/TiendaNube.png";
import MercadoLibreLogo from "../images/MercadoLibre.png";
import MercadoPagoLogo from "../images/MercadoPago.png";

const Integrations = () => {
  return (
    <section className="relative z-20 overflow-hidden pt-8 pb-8 lg:pb-[60px]">
      <div className="container">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[620px] text-center lg:mb-20">
              <span className="mb-2 block text-lg font-semibold text-primary">
                Integraciones
              </span>
              <p className="text-lg leading-relaxed text-body-color sm:text-xl sm:leading-relaxed">
                Nos integramos con algunos de los sistemas más utilizados para
                poder ayudar a potenciar tu negocio.
              </p>
            </div>
          </div>
        </div>

        <div className="-mx-4 flex flex-wrap">
          <div className="w-full flex justify-around px-4 lg:w-1/2">
            <img className="w-[200px] h-[200px]" src={AfipLogo} alt="AFIP" />
            <img
              className="w-[200px] h-[200px]"
              src={TiendaNubeLogo}
              alt="Tienda Nube"
            />
          </div>
          <div className="w-full flex justify-around px-4 lg:w-1/2">
            <img
              className="w-[200px] h-[200px]"
              src={MercadoPagoLogo}
              alt="Mercado Pago"
            />
            <img
              className="w-[200px] h-[200px]"
              src={MercadoLibreLogo}
              alt="Mercado Libre"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Integrations;
