const About = () => {
  return (
    <section
      id="about"
      className="bg-[#f3f4fe] pt-20 pb-20 lg:pt-[120px] lg:pb-[120px]"
    >
      <div className="container">
        <div className="wow fadeInUp bg-white" data-wow-delay=".2s">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="items-center justify-between overflow-hidden border lg:flex">
                <div className="w-full py-12 px-7 sm:px-12 md:p-16 lg:max-w-[565px] lg:py-9 lg:px-16 xl:max-w-[640px] xl:p-[70px]">
                  <span className="mb-5 inline-block bg-primary py-2 px-5 text-sm font-medium text-white">
                    Quienes somos
                  </span>
                  <h2 className="mb-6 text-3xl font-bold text-dark sm:text-4xl sm:leading-snug 2xl:text-[40px]">
                    Daruma - Tu próximo punto de venta
                  </h2>
                  <p className="mb-6 text-base leading-relaxed text-body-color">
                    Daruma es un software de punto de venta diseñado para
                    ofrecer a las empresas una solución sencilla y eficiente
                    para llevar el control de sus ventas y operaciones
                    comerciales. Nuestro equipo está compuesto por expertos en
                    tecnología y en el área de negocios, y trabajamos
                    constantemente para mejorar y actualizar nuestro software de
                    acuerdo a las necesidades y demandas de nuestros clientes.
                  </p>
                  <p className="mb-6 text-base leading-relaxed text-body-color">
                    Nuestro objetivo es ayudar a las empresas a optimizar sus
                    procesos de venta y gestión, ofreciendo una herramienta
                    fácil de usar y con un amplio rango de funcionalidades. Con
                    Daruma, las empresas pueden llevar un control preciso de sus
                    inventarios, generar reportes detallados y tomar decisiones
                    informadas para mejorar su rendimiento.
                  </p>
                  <p className="mb-6 text-base leading-relaxed text-body-color">
                    Además, ofrecemos un servicio de atención al cliente
                    excepcional para asegurarnos de que nuestros usuarios tengan
                    la mejor experiencia posible con nuestro software. Estamos
                    comprometidos a ayudar a nuestros clientes a tener éxito en
                    su negocio y a ofrecerles una solución de punta de venta de
                    alta calidad. ¡Esperamos poder trabajar contigo y ayudarte a
                    llevar tu negocio al siguiente nivel!
                  </p>
                </div>
                <div className="text-center">
                  <div className="relative z-10 inline-block">
                    <img
                      src="images/about/about-image.svg"
                      alt=""
                      className="mx-auto lg:ml-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
